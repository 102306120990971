<script setup lang="ts">
interface Props {
  /** @default 100  */
  zIndex?: number
  /** @default 8 */
  offset?: number | string
  /**
   * 兼容target为fixed的情况
   * @default absolute
   */
  position?: 'absolute' | 'fixed'
  /**
   * todo: 对齐方向
   * @default center
   */
  align?: 'start' | 'center' | 'end'
  /**
   * 是否允许通过点击外部关闭
   * @default true
   */
  closeByMask?: boolean

  mainClass?: any
}

defineOptions({ inheritAttrs: false })

const props = withDefaults(defineProps<Props>(), {
  zIndex: 100,
  offset: 8,
  position: 'absolute',
  align: 'center',
  closeByMask: true,
})

const emits = defineEmits<{
  (e: 'open'): void
  (e: 'close'): void
}>()

const visible = defineModel<boolean>({ default: false })

const ofssetStyle = ref<Record<string, string>>({})

const target = ref<HTMLElement>()

function generateOffset() {
  if (!target.value) {
    console.warn('popover target element is undefined')
    ofssetStyle.value = {}
    return
  }

  const { left, width, top, height } = target.value.getBoundingClientRect()
  ofssetStyle.value = {
    'position': props.position,
    'left': `${left + width / 2}px`,
    'top': `${top + height}px`,
    'padding-top': `${props.offset}px`,
    '--un-translate-x': '-50%',
  }

  const isRtl = document?.querySelector('html[dir]')?.getAttribute('dir') === 'rtl'
  if ((props.align === 'start' && !isRtl) || (props.align === 'end' && isRtl)) {
    ofssetStyle.value.left = `${left}px`
    ofssetStyle.value['--un-translate-x'] = '0'
  }
  else if ((props.align === 'start' && isRtl) || (props.align === 'end' && !isRtl)) {
    ofssetStyle.value.left = `${left + width}px`
    ofssetStyle.value['--un-translate-x'] = '-100%'
  }
}

function toggle(el?: HTMLElement) {
  target.value = el
  generateOffset()

  visible.value ? close() : open()
}

function open() {
  visible.value = true
  emits('open')
}

function close() {
  visible.value = false
  emits('close')
}

onMounted(() => {
  window.addEventListener('resize', generateOffset)
})

onUnmounted(() => {
  window.removeEventListener('resize', generateOffset)
})

defineExpose({
  toggle,
  close,
})
</script>

<template>
  <Teleport to="body">
    <!-- popover component -->
    <Transition name="popover-visible">
      <div v-if="visible" aria-modal="true" class="fixed inset-0 z-30 of-y-auto overscroll-none [&::-webkit-scrollbar]:size-0px">
        <!-- The style `overscroll-none of-y-scroll` and `h="[calc(100%+0.5px)]"` is used to implement scroll locking, -->
        <!-- mask layer -->
        <div
          v-if="closeByMask"
          class="popover-mask absolute inset-0 z-0 h-[calc(100%+0.5px)] touch-none bg-transparent"
          @click="close"
        />
        <!-- popover container -->
        <div class="pointer-events-none absolute inset-0 z-1 flex op-100">
          <div class="transform-gpu" :style="ofssetStyle" v-bind="$attrs">
            <div class="popover-main pointer-events-auto isolate max-h-full touch-pan-x touch-pan-y of-y-auto overscroll-contain" :class="mainClass">
              <slot />
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<style lang="scss" scoped>
.popover-visible-enter-active,
.popover-visible-leave-active {
  transition-duration: 0.2s;

  .popover-main {
    transition:
      opacity 0.2s ease,
      transform 0.15s ease;
  }
}

.popover-visible-enter-from,
.popover-visible-leave-to {
  .popover-main {
    transform: translateY(-10px);
    opacity: 0;
  }
}
</style>

<script setup lang="ts">
import { overseaLogin } from 'ld-web-sdk'
import SVG_EARTH_REVERSE_ICON from '~/assets/svg/earth-reverse-icon.svg'
import SVG_USERINFO_PENCIL_ICON from '~/assets/svg/userinfo-pencil-icon.svg'
import SVG_SIGN_OUT_ICON from '~/assets/svg/sign-out-icon.svg'
import type { LdPopover } from '#components'
import { API_USER_IP, API_USER_REMAINING_TIME } from '~/api'

const { userInfo, waitSdkInit, cleanUserInfo, userIp, remainingTime } = useUserInfo()
const newNickname = ref(userInfo.value?.nickname || '')

const isEditStatus = ref(false)
const inputRef = ref<HTMLInputElement>()

// const tipsRef = ref<HTMLElement>()
const tipsVisible = ref(false)
const popoverRef = ref<InstanceType<typeof LdPopover>>()

async function updateNickname() {
  if (newNickname.value === userInfo.value?.nickname) {
    isEditStatus.value = false
    return
  }

  await waitSdkInit.value
  await overseaLogin.updateUserBasicInfo({ nickname: newNickname.value, headPortraitUrl: userInfo.value.headPortraitUrl })
  userInfo.value.nickname = newNickname.value
  isEditStatus.value = false
}

async function focusInputEdit() {
  isEditStatus.value = true
  await nextTick()
  inputRef.value?.focus()
}

const refreshHour = computed(() => userIp.value?.secondsUntilTomorrowMidnight ? String(Math.floor(userIp.value?.secondsUntilTomorrowMidnight / 60 / 60)) : '*')

const remainStr = computed<[string, string, string]>(() => {
  if (remainingTime.value === undefined)
    return ['**', '**', '**'] as const

  const HH = String(Math.floor(remainingTime.value / 60 / 60)).padStart(2, '0')
  const MM = String(Math.floor(remainingTime.value / 60) % 60).padStart(2, '0')
  const SS = String(Math.floor(remainingTime.value % 3600 % 60)).padStart(2, '0')
  return [HH, MM, SS] as const
})

onBeforeMount(async () => {
  const { shortToken, uid } = userInfo.value
  const [ip, time] = await Promise.all([API_USER_IP(), API_USER_REMAINING_TIME({ token: shortToken, uid })])
  userIp.value = ip
  remainingTime.value = time
})
</script>

<template>
  <div lt-lg:landscape="relative w-100vw" lt-md:portrait="relative w-100vw">
    <!-- fix scroll lock with 0.5px -->
    <div class="absolute left-0 hidden h-0.25 w-full bg-gray-3 -bottom-0.25" lt-lg:landscape="block" lt-md:portrait="block" />

    <div
      class="relative z-10 w-110 flex flex-col rd-4 bg-gray-3 p-10"
      lt-lg:landscape="w-full rd-0 px-5 py-6" lt-md:portrait="px-5 py-6 w-full rd-0"
    >
      <section class="w-full flex items-center justify-between">
        <div class="flex items-center">
          <div class="relative">
            <div class="absolute size-full rd-full" b="3 solid #fff op-90" />
            <img :src="userInfo.headPortraitUrl" alt="avatar" class="size-13 rd-full">
          </div>

          <div class="mis-4">
            <div class="flex items-center">
              <div class="mie-3 flex-center gap-2 rd-2 bg-gray-4 p-2 text-3.5 c-#fff c-op-90 lt-md:py-1.5 md:text-4">
                <SVG_EARTH_REVERSE_ICON class="size-4" />
                <span class="font-600">{{ userIp?.countryCode || '**' }}</span>
              </div>
              <div class="flex items-center" @dblclick.stop="focusInputEdit">
                <template v-if="isEditStatus">
                  <input
                    ref="inputRef" v-model="newNickname" autofocus type="text"
                    class="max-w-42 flex-center rd-1.5 bg-gray-4 px-2 pb-1.5 pt-1.25 text-4 c-#fff c-op-90 font-600 lh-1em font-unset caret-green-5 -mis-2"
                    lt-lg:landscape="max-w-40vw pb-1 pt-0.75" lt-md:portrait="max-w-40vw pb-1 pt-0.75"
                    @blur="updateNickname" @keypress.enter="updateNickname" @click.stop
                  >
                </template>
                <template v-else>
                  <span
                    class="max-w-35 truncate text-4 c-#fff c-op-90 font-600 lh-8"
                    lt-lg:landscape="max-w-32vw lh-7" lt-md:portrait="max-w-32vw lh-7"
                  >
                    {{ userInfo.nickname }}
                  </span>
                  <SVG_USERINFO_PENCIL_ICON class="mis-2 cursor-pointer" @click.stop="focusInputEdit" />
                </template>
              </div>
            </div>
            <p class="mt-2 text-3.5 c-#fff c-op-50 lh-3" md="text-3 mt-1.5">
              {{ userInfo.email }}
            </p>
          </div>
        </div>

        <button class="size-8 cursor-pointer rd-2 bg-gray-4 transition-300 hover:bg-gray-5" @click="cleanUserInfo">
          <SVG_SIGN_OUT_ICON />
        </button>
      </section>

      <div class="mt-10">
        <div class="flex items-center justify-between">
          <p class="text-6 c-#fff c-op-90 font-[OSP-DIN]">
            {{ $t('userCenter.playTimeTitle') }}
          </p>
          <!-- cursor-pointer @click="popoverRef?.toggle(tipsRef)" -->
          <p class="group">
            <I18nT keypath="userCenter.playTimeResetTips" tag="span" class="text-3.5 c-#fff c-op-50 lh-4 md:text-3">
              <template #hour>
                <span class="mx-0.5 c-#fff c-op-90 font-600">{{ refreshHour }}</span>
              </template>
            </I18nT>
          </p>
        </div>

        <div class="mt-6 flex-center rd-3 bg-gray-4 p-5">
          <div>
            <span class="text-8 c-green-5 font-[OSP-DIN]">
              {{ remainStr[0] }}
            </span>
            <span class="mis-1 v-top text-2.5 c-#fff c-op-90 font-700">H</span>
          </div>
          <svg class="mx-4 c-#fff c-op-30" width="4" height="8" viewBox="0 0 4 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.34302 0.502337C2.43641 0.203483 2.71319 0 3.02629 0C3.50924 0 3.85362 0.468419 3.70957 0.929383L1.65698 7.49766C1.56359 7.79652 1.28681 8 0.973706 8C0.490758 8 0.146381 7.53158 0.290432 7.07062L2.34302 0.502337Z" fill="currentColor" />
          </svg>
          <div>
            <span class="text-8 c-green-5 font-[OSP-DIN]">
              {{ remainStr[1] }}
            </span>
            <span class="mis-1 v-top text-2.5 c-#fff c-op-90 font-700">M</span>
          </div>
          <svg class="mx-4 c-#fff c-op-30" width="4" height="8" viewBox="0 0 4 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.34302 0.502337C2.43641 0.203483 2.71319 0 3.02629 0C3.50924 0 3.85362 0.468419 3.70957 0.929383L1.65698 7.49766C1.56359 7.79652 1.28681 8 0.973706 8C0.490758 8 0.146381 7.53158 0.290432 7.07062L2.34302 0.502337Z" fill="currentColor" />
          </svg>
          <div>
            <span class="text-8 c-green-5 font-[OSP-DIN]">
              {{ remainStr[2] }}
            </span>
            <span class="mis-1 v-top text-2.5 c-#fff c-op-90 font-700">S</span>
          </div>
        </div>

        <button v-if="false" class="ld-button mt-6 h-11 w-full ld-button-green">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd" clip-rule="evenodd" fill="#ffffffe6"
              d="M2.52494 0.037404C1.09383 0.265089 0 1.50481 0 3L4.5 3L2.52494 0.037404ZM4.5 0L6.5 3H9.5L7.5 0H4.5ZM9.5 0L11.5 3H14C14 1.34315 12.6569 0 11 0H9.5ZM14 5H0V11C0 12.6569 1.34315 14 3 14H11C12.6569 14 14 12.6569 14 11V5ZM5.25 8.26619C5.25 7.4889 6.09797 7.00878 6.7645 7.4087L8.82084 8.64251C9.46818 9.03091 9.46818 9.96909 8.82084 10.3575L6.7645 11.5913C6.09797 11.9912 5.25 11.5111 5.25 10.7338V8.26619Z"
            />
          </svg>
          <span class="mis-1">{{ $t('userCenter.seeAdsBtn') }}</span>
        </button>
      </div>
    </div>
  </div>

  <LdPopover ref="popoverRef" position="fixed" align="end" main-class="mie--5" @open="tipsVisible = true" @close="tipsVisible = false">
    <div class="max-w-100 shadow-[0_20px_60px_0_#0005]">
      <div class="flex justify-end">
        <svg class="mie-4 c-gray-4" width="24" height="6" viewBox="0 0 24 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.9" d="M13.8 1.35L17.8667 4.4C19.2514 5.43858 20.9357 6 22.6667 6L1.33333 6C3.06429 6 4.74856 5.43858 6.13333 4.4L10.2 1.35C11.2667 0.55 12.7333 0.55 13.8 1.35Z" fill="currentcolor" />
        </svg>
      </div>

      <div class="rd-3 bg-gray-4 p-10">
        <p class="text-4 c-#fff c-op-90 font-700">
          Duration description
        </p>
        <div class="mt-6 text-3.5 c-#fff c-op-50 lh-7">
          <p>1. Please check the remaining game time today to avoid affecting the game progress.</p>
          <p>2. You can click [ More playtime ] to increase the playtime.</p>
          <p>3. Play time will be reset every day, please pay attention to the time resetreminder</p>
        </div>
      </div>
    </div>
  </LdPopover>
</template>

<style lang="scss" scoped>
</style>
